import React from 'react';
import './header.scss';

export const Header = () => {
    return (
        <div className="header">
            Хлебные крошки
        </div>
    );
};
